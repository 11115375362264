import React from "react";
import { Route, Switch } from "react-router";

import { LayoutAdmin } from "../layouts/admin/LayoutAdmin";

import { MonitoringPickupContainer } from "../containers/monitoring-pickup/MonitoringPickupContainer";
import { MonitoringPickupDetailAwbContainer } from "../containers/monitoring-pickup/MonitoringPickupDetailAwb";
import { MonitoringPickupDetailBranchContainer } from "../containers/monitoring-pickup/MonitoringPickupDetailBranch";
import { CapacityPickupContainer } from "../containers/capacity-pickup/CapacityPickupContainer";
// import { TimeWindowMongoContainer } from "../containers/time-window-mongo/TimeWindowMongoContainer";
// import { TimeWindowDetailBranchMongo } from "../containers/time-window-mongo/TimeWindowDetailBranchMongo";
// import { TimeWindowDetailBranchDriverMongo } from "../containers/time-window-mongo/TimeWindowDetailBranchDriverMongo";
// import { TimeWindowDetailAwbMongo } from "../containers/time-window-mongo/TimeWindowDetailAwbMongo";
import { CapacityPickupMongoContainer } from "../containers/capacity-pickup-mongo/CapacityPickupMongoContainer";
// import { TimewindowDetailMongoAllUnpickContainer } from "../containers/time-window-mongo/Unpick/TimeWindowDetailAllUnpickContainer";
// import { TimeWindowPeriodSummaryContainer } from "../containers/TimeWindowPeriodSummary/TimeWindowPeriodSummaryContainer";
import { TimewindowPeriodContainer } from "../containers/TimeWindowPeriodSummary/TimeWindowPeriodContainer";
// import { TimeWindowDetailBranchDriverAllMongo } from "../containers/time-window-mongo/TimeWindowDetailBranchDriverAllMongo";
import { MonitoringImageContainer } from "../containers/monitoring-image/MonitoringImageContainer";
import { TimeWindowContainer } from "../containers/time-window/TimeWindowContainer";
import { TimeWindowDetailAwb } from "../containers/time-window/TimeWindowDetailAwb";
import { TimeWindowDetailBranch } from "../containers/time-window/TimeWindowDetailBranch";
import { TimeWindowDetailBranchDriver } from "../containers/time-window/TimeWindowDetailBranchDriver";
import { TimewindowDetailAllUnpickContainer } from "../containers/time-window/Unpick/TimeWindowDetailAllUnpickContainer";
import { TimeWindowContainer as TimeWindowNewContainer } from "../containers/time-window-new/TimeWindowContainer";
import { TimeWindowDetailAwb as TimeWindowNewDetailAwb } from "../containers/time-window-new/TimeWindowDetailAwb";
import { TimeWindowBranchDetailContainer as TimeWindowNewDetailBranch } from "../containers/time-window-new/TimeWindowBranchDetailContainer";
import { TimeWindowDriverDetailContainer as TimeWindowNewDetailBranchDriver } from "../containers/time-window-new/TimeWindowDriverDetailContainer";
import { TimewindowDetailAllUnpickContainer as TimewindowNewDetailAllUnpickContainer } from "../containers/time-window-new/Unpick/TimeWindowDetailAllUnpickContainer";
// import MonitoringPerformance from "../containers/embed-dashboard/MonitoringPerformance";
import MonitoringProblem from "../containers/embed-dashboard/MonitoringProblem";
import MonitoringCourierPickup from "../containers/embed-dashboard/MonitoringCourierPickup";
import MonitoringSTT from "../containers/embed-dashboard/MonitoringSTT";
import MonitoringSlaPickup from "../containers/embed-dashboard/MonitoringSlaPickup";

export const MonitoringRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path="/admin/monitoring-pickup"
        render={(props) => (
          <LayoutAdmin>
            <MonitoringPickupContainer {...props} />
          </LayoutAdmin>
        )}
      />
      <Route
        exact
        path="/admin/monitoring-pickup/detail/awb"
        render={(props) => (
          <LayoutAdmin>
            <MonitoringPickupDetailAwbContainer {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/monitoring-pickup/detail/branch"
        render={(props) => (
          <LayoutAdmin>
            <MonitoringPickupDetailBranchContainer {...props} />
          </LayoutAdmin>
        )}
      />

      {/* start menu time window */}
      <Route
        exact
        path="/admin/time_window"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowContainer {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/time_window/detail/branch"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowDetailBranch {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/time_window/detail/branch/driver"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowDetailBranchDriver {...props} />
          </LayoutAdmin>
        )}
      />
      <Route
        exact
        path="/admin/time_window/detail/unpick"
        render={(props) => (
          <LayoutAdmin>
            <TimewindowDetailAllUnpickContainer {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/time_window/detail/awb"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowDetailAwb {...props} />
          </LayoutAdmin>
        )}
      />
      {/* end menu time window */}

      {/* start menu time window new */}
      <Route
        exact
        path="/admin/time-window-new"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowNewContainer {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/time-window-new/branch-detail"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowNewDetailBranch {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/time-window-new/driver-detail"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowNewDetailBranchDriver {...props} />
          </LayoutAdmin>
        )}
      />
      <Route
        exact
        path="/admin/time-window-new/detail/unpick"
        render={(props) => (
          <LayoutAdmin>
            <TimewindowNewDetailAllUnpickContainer {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/time-window-new/detail/awb"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowNewDetailAwb {...props} />
          </LayoutAdmin>
        )}
      />
      {/* end menu time window */}

      {/* start menu time window mongo */}
      {/* <Route
        exact
        path="/admin/time_window_mongo"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowMongoContainer {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/time_window_mongo/detail/branch/mongo"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowDetailBranchMongo {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/time_window_mongo/detail/branch/driver/mongo"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowDetailBranchDriverMongo {...props} />
          </LayoutAdmin>
        )}
      />
      <Route
        exact
        path="/admin/time_window_mongo/detail/branch/driver/all/mongo"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowDetailBranchDriverAllMongo {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/time_window_mongo/detail/awb/mongo"
        render={(props) => (
          <LayoutAdmin>
            <TimeWindowDetailAwbMongo {...props} />
          </LayoutAdmin>
        )}
      />
      <Route
        exact
        path="/admin/time_window_mongo/detail/unpick/mongo"
        render={(props) => (
          <LayoutAdmin>
            <TimewindowDetailMongoAllUnpickContainer {...props} />
          </LayoutAdmin>
        )}
      /> */}
      {/* end menu time window mongo */}

      {/* time window mongo Period*/}
      <Route
        exact
        path="/admin/time_window_period"
        render={(props) => (
          <LayoutAdmin>
            <TimewindowPeriodContainer {...props} />
          </LayoutAdmin>
        )}
      />
      {/* end menu time window mongo Period*/}
      <Route
        exact
        path="/admin/capacity-pickup"
        render={(props) => (
          <LayoutAdmin>
            <CapacityPickupContainer {...props} />
          </LayoutAdmin>
        )}
      />
      <Route
        exact
        path="/admin/capacity-pickup-mongo"
        render={(props) => (
          <LayoutAdmin>
            <CapacityPickupMongoContainer {...props} />
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/monitoring/showImage/:id"
        render={(props) => (
          <LayoutAdmin>
            <MonitoringImageContainer {...props} />
          </LayoutAdmin>
        )}
      />
            {/* <Route //di hide
        exact
        path="/admin/monitoring-performance"
        render={(props) => (
          <LayoutAdmin>
            <MonitoringPerformance/>
          </LayoutAdmin>
        )}
      /> */}

      <Route
        exact
        path="/admin/monitoring-problem"
        render={(props) => (
          <LayoutAdmin>
            <MonitoringProblem/>
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/monitoring-courir-pickup"
        render={(props) => (
          <LayoutAdmin>
            <MonitoringCourierPickup/>
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/monitoring-stt"
        render={(props) => (
          <LayoutAdmin>
            <MonitoringSTT/>
          </LayoutAdmin>
        )}
      />

      <Route
        exact
        path="/admin/monitoring-sla-pickup"
        render={(props) => (
          <LayoutAdmin>
            <MonitoringSlaPickup/>
          </LayoutAdmin>
        )}
      />
    </Switch>
  );
};
