import "./LayoutAdmin.scss";

import classNames from "classnames";
import { ScrollPanel } from "primereact/components/scrollpanel/ScrollPanel";
import React from "react";

import { LayoutAdminInlineProfile } from "./LayoutAdminInlineProfile";
import { LayoutAdminMenu } from "./LayoutAdminMenu";
import { LayoutAdminTopbar } from "./LayoutAdminTopbar";
import { Redirect } from "react-router-dom";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import queryString from 'query-string';
import { observable } from "mobx";

export class LayoutAdmin extends React.Component<any, any> {
  menuClick: any;
  menu: any;
  userData: any;
  sidebar: HTMLDivElement | null;

  constructor(props) {
    super(props);

    this.state = {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
    };


    this.userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);


  }

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  createMenu() {
    this.menu = [
      {
        label: "Time Window",
        icon: "pi pi-fw pi-list",
        command: () => {
          window.location = "/admin/time-window-new" as any;
        },
        href: "/admin/time-window-new",
        externalLink: false,
        newtab: false,
        roles: []
      },
      {
        label: "Metabase",
        icon: "pi pi-fw pi-list",
        newtab: false,
        roles: [
          // "Ops - Last Mile Koordinator Sigesit",
          // "Ops - Last Mile Koordinator Sigesit New",
          // "Ops - Last Mile Koordinator Sigesit (COD)"
        ],
        items: [
          // { //dihide
          //   label: "Monitoring Performance",
          //   icon: "pi pi-fw pi-calendar",
          //   command: () => {
          //     window.location = "/admin/monitoring-performance" as any;
          //   },
          //   href: "/admin/monitoring-performance",
          //   externalLink: false,
          //   newtab: false,
          //   roles: [ ],
          // },
          {
            label: "Monitoring Problem",
            icon: "pi pi-fw pi-calendar",
            command: () => {
              window.location = "/admin/monitoring-problem" as any;
            },
            href: "/admin/monitoring-problem",
            externalLink: false,
            newtab: false,
            roles: [ ],
          },
          {
            label: "Monitoring Courier Pickup",
            icon: "pi pi-fw pi-calendar",
            command: () => {
              window.location = "/admin/monitoring-courir-pickup" as any;
            },
            href: "/admin/monitoring-courir-pickup",
            externalLink: false,
            newtab: false,
            roles: [ ],
          },
          {
            label: "Monitoring STT",
            icon: "pi pi-fw pi-calendar",
            command: () => {
              window.location = "/admin/monitoring-stt" as any;
            },
            href: "/admin/monitoring-stt",
            externalLink: false,
            newtab: false,
            roles: [ ],
          },
          {
            label: "Monitoring SLA Pickup",
            icon: "pi pi-fw pi-calendar",
            command: () => {
              window.location = "/admin/monitoring-sla-pickup" as any;
            },
            href: "/admin/monitoring-sla-pickup",
            externalLink: false,
            newtab: false,
            roles: [ ],
          },
        ]
      },
      {
        label: "Prosedur dan Ketentuan",
        icon: "pi pi-fw pi-file",
        command: () => {
          window.location = "https://sicepatid-my.sharepoint.com/:f:/g/personal/murwanto_sicepat_com/Eh1XWKpEw61ClSGppRFlTE4BA-MDTOsFiph3RYvJ_zi1cw?e=W16EgX" as any;
        },
        href:
          "https://sicepatid-my.sharepoint.com/:f:/g/personal/murwanto_sicepat_com/Eh1XWKpEw61ClSGppRFlTE4BA-MDTOsFiph3RYvJ_zi1cw?e=W16EgX",
        externalLink: true,
        newtab: true,
        roles: [],
      },
      // {
      //   label: "Attendance Driver",
      //   icon: "pi pi-fw pi-calendar",
      //   command: () => {
      //     window.location = "/admin/attendance-driver" as any;
      //   },
      //   href: "/admin/attendance-driver",
      //   externalLink: false,
      //   newtab: false,
      //   roles: ["Superadmin", "Control Tower", "Admin Hub"],
      // },
    ];
  }

  public createMenuTMS() {
    this.menu = [
      {
        label: "Time Window",
        icon: "pi pi-fw pi-list",
        command: () => {
          window.location = "/admin/time-window-new" as any;
        },
        href: "/admin/time-window-new",
        externalLink: false,
        newtab: false,
        roles: []
      },
    ];
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else {
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  }

  render() {
    if (!localStorage.getItem("userData")) {
      return <Redirect to={"/"} />;
    }
    // console.log(location.search, this.userData.platform, "userData")
    // const parsed = queryString.parse(location.search);
    if (this.userData.platform === "tms" && !this.userData.isHeadOffice) {
      this.createMenuTMS();
    } else {
      this.createMenu();
    }

    // const logo =
    //   this.state.layoutColorMode === 'dark'
    //     ? 'assets/layout/images/logo-white.svg'
    //     : 'assets/layout/images/logo.svg';

    const logo = "/assets/logo-sicepat.png";

    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
    });
    const sidebarClassName = classNames("layout-sidebar", {
      "layout-sidebar-dark": this.state.layoutColorMode === "dark",
    });

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <LayoutAdminTopbar onToggleMenu={this.onToggleMenu} />

        <div
          ref={(el) => (this.sidebar = el)}
          className={sidebarClassName}
          onClick={this.onSidebarClick}
        >
          <ScrollPanel style={{ height: "100%" }}>
            <div className="layout-sidebar-scroll-content">
              <div className="layout-logo">
                <img
                  alt="Logo"
                  src={logo}
                  style={{ width: "172px", margin: "auto" }}
                />
              </div>
              <LayoutAdminInlineProfile />
              <LayoutAdminMenu
                model={this.menu}
                onMenuItemClick={this.onMenuItemClick}
              />
            </div>
          </ScrollPanel>
        </div>

        <div className="layout-main">
          {this.props.tag && (
            <div className="layout-tag absolute -mt-5 -ml-5">{this.props.tag}</div>
          )}
          {this.props.children}
        </div>

        <div className="layout-mask" />
      </div>
    );
  }
}
